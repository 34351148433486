import React from "react";
import Query from "../Query";
import logoBlog from "../../images/logo_blog.png"
import logoFaniak from "../../images/logo_faniak.png"
// import { Link } from "react-router-dom";

import CATEGORIES_QUERY from "../../queries/category/categories";

const Nav = () => {
  return (
    <div>
      <Query query={CATEGORIES_QUERY} id={null}>
        {({ data: { categories } }) => {
          return (
            <div>
              <nav className="uk-navbar-container" data-uk-navbar>
                <div className="uk-navbar-left">
                  <a href="/" className="uk-logo uk-text-uppercase">
                    <img className="blog-logo" src={logoBlog} alt="B#" /> <strong>-</strong> Faniak's Blog
                  </a>
                </div>
                <div className="uk-navbar-right">
                  <a href="https://www.faniak.com/" className="uk-logo">
                    <img className="faniak-logo" src={logoFaniak} alt="Faniak" />
                  </a>
                </div>
                {/*
                <div className="uk-navbar-right">
                  <ul className="uk-navbar-nav">
                    {categories.map((category, i) => {
                      return (
                        <li key={category.id}>
                          <Link
                            to={`/category/${category.id}`}
                            className="uk-link-reset"
                          >
                            {category.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                */}
              </nav>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default Nav;
