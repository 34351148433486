import React from "react";
import { Link } from "react-router-dom";
import readingTime from "reading-time";

const Card = ({ article, highlight }) => {
  const imageUrl =
    process.env.NODE_ENV !== "development"
      ? article.image.url
      : process.env.REACT_APP_BACKEND_URL + article.image.url;
  
  const avatarUrl = article.user.avatar ? (
    process.env.NODE_ENV !== "development"
      ? article.user.avatar.url
      : process.env.REACT_APP_BACKEND_URL + article.user.avatar.url) : null;

  article.reading_time = article.reading_time ? `${article.reading_time} min read` : readingTime(article.content).text;

  let subtitle = article.subtitle;

  if(!highlight) {
    const firstLine = article.subtitle.match(/[^.!]+[.!]+/g);

    if(firstLine && firstLine.length > 0) {
      subtitle = firstLine[0];
    }
  }

  return (
    <Link to={`/article/${article.slug}/`} className="uk-link-reset">
      <div className="uk-card uk-card-muted">
        <div className="uk-card-media-top uk-cover-container">
          <img data-src={imageUrl} alt={article.image.url} data-uk-img="" data-uk-cover />
        </div>
        <div className="uk-card-body">
          {article.category && (
            <p id="category" className="uk-text-meta uk-text-capitalize">
              {article.category.name}
            </p>
          )}
          <h2 className="uk-heading">
            {article.title}
          </h2>
          <p className="uk-margin-remove-top">
            {subtitle}
          </p>
          <div className="uk-flex uk-flex-between">
            <div className="author">
              <div className="avatar uk-border-circle" data-src={avatarUrl} data-uk-img="" /> 
              <span className="name">{article.user.name}</span>
            </div>
            <div className="uk-text-right uk-text-small">[{article.reading_time}]</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
