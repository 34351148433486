import gql from "graphql-tag";

const ARTICLES_QUERY = gql`
  query Articles($today: String!) {
    articles(sort: "published_at:desc", where: { published_at_lte: $today }) {
      id
      slug
      title
      subtitle
      content
      category {
        id
        name
      }
      image {
        url
      }
      highlight
      user {
        username
        name
        avatar {
          url
        }
      }
      reading_time
    }
  }
`;

export default ARTICLES_QUERY;
