import React from "react";
import Articles from "../../components/Articles";
import Query from "../../components/Query";
import ARTICLES_QUERY from "../../queries/article/articles";

const Home = () => {
  return (
    <div className="uk-section">
      <div className="uk-container">
        <Query query={ARTICLES_QUERY} today={new Date()}>
          {({ data: { articles } }) => {
            return <Articles articles={articles} />;
          }}
        </Query>
      </div>
    </div>
  );
};

export default Home;
