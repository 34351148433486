import React from "react";

const Footer = () => {
  return (
    <footer className="footer uk-container uk-container-expand">
      <img data-src="https://faniak-staging-static.s3.amazonaws.com/static/landingpage/images/landingpage_v6/i-bsharp.png" width="80" alt="B#" data-uk-img="" />
      <p className="uk-text-lead">Faniak’s Fortnightly Digest for Business-Savvy Musicians</p>
      <a className="uk-button uk-button-secondary uk-text-capitalize" href="https://faniak.activehosted.com/f/1" target="_blank" rel="noopener noreferrer">Subscribe To Our Newsletter</a>
    </footer>
  );
};

export default Footer;
