import React from "react";
import { useQuery } from "@apollo/react-hooks";

const Query = ({ children, query, id, today }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { id: id, today: today }
  });

  if (loading) return <p></p>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({ data });
};

export default Query;
