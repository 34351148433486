import React from "react";
import Card from "../Card";

const Articles = ({ articles }) => {
  // Should have only one highlight article
  const highlight = articles.filter(article => article.highlight === true).shift();

  return (
    <div className="uk-flex-center">
      <div className="uk-width-2-3@m uk-margin-auto">
        <Card article={highlight} highlight key={`article__${highlight.id}`} />
      </div>
      <hr className="uk-hr" />
      <div className="uk-child-width-1-3@m uk-grid-match" data-uk-grid>
        {articles.filter(article => article !== highlight).map(article => {
          return <Card article={article} key={`article__${article.id}`} />;
        })}
      </div>
    </div>
  );
};

export default Articles;
