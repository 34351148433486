import React from "react";
import ReactDOM from "react-dom";
import 'core-js';
import { ApolloProvider } from "react-apollo";
import App from "./containers/App";
import client from "./utils/apolloClient";
import { BrowserRouter as Router } from "react-router-dom";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import "./index.scss";

UIkit.use(Icons);

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);
