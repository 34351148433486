import React from "react";
import RelArticlesComp from "../../components/RelatedArticles";
import Query from "../../components/Query";
import CATEGORY_ARTICLES_QUERY from "../../queries/category/articles";

const RelatedArticles = ({articleID, categoryID}) => {
  return (
    <Query query={CATEGORY_ARTICLES_QUERY} id={categoryID} today={new Date()}>
      {({ data: { category } }) => {
        return category.articles.length > 1 ? (
          <div className="uk-section">
            <div className="uk-container">
              <RelArticlesComp id={articleID} articles={category.articles} />
            </div>
          </div>
        ) : null
      }}
    </Query>
  );
};

export default RelatedArticles;
