import gql from "graphql-tag";

const ARTICLE_QUERY = gql`
  query Articles($id: String!) {
    articles(where: {slug: $id}) {
      id
      slug
      title
      subtitle
      content
      image {
        url
      }
      category {
        id
        name
      }
      highlight
      published_at
      user {
        username
        name
        position
        avatar {
          url
        }
      }
      tags {
        id
        tag
      }
      reading_time
    }
  }
`;

export default ARTICLE_QUERY;
