import React from "react";
import Card from "../Card";

const RelatedArticles = ({ id, articles }) => {
  const relatedArticles = articles.filter(article => article.id !== id);
  return relatedArticles.length > 0 ? (
    <div className="uk-flex-center">
      <h5 className="uk-text-bold uk-text-primary uk-text-uppercase uk-margin-small-bottom uk-margin-left">Related</h5>
      <hr className="uk-hr uk-margin-remove-top" />
      <div className="uk-child-width-1-3@m uk-grid-match" data-uk-grid>
        {relatedArticles.map((article, i) => {
          return <Card article={article} key={`article__${article.id}`} />;
        })}
      </div>
    </div>
  ) : null;
};

export default RelatedArticles;
