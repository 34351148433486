import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import MetaTags from 'react-meta-tags';
import ReactMarkdown from "react-markdown/with-html";
import Moment from "react-moment";
import readingTime from "reading-time";
import { AllHtmlEntities as Entities } from "html-entities";
import { FacebookShareButton, FacebookIcon,
         LinkedinShareButton, LinkedinIcon,
         TwitterShareButton, TwitterIcon } from "react-share";
import RelatedArticles from "./../RelatedArticles";

import ARTICLE_QUERY from "../../queries/article/article";

const Article = () => {
  let { slug } = useParams();
  const entities = new Entities();

  return (
    <Query query={ARTICLE_QUERY} id={slug}>
      {({ data: { articles } }) => {
        const article = articles[0];

        const imageUrl =
          process.env.NODE_ENV !== "development"
            ? article.image.url
            : process.env.REACT_APP_BACKEND_URL + article.image.url;

        const avatarUrl = article.user.avatar ? (
          process.env.NODE_ENV !== "development"
            ? article.user.avatar.url
            : process.env.REACT_APP_BACKEND_URL + article.user.avatar.url) : null;

        // this is beyond macgyver
        article.content = entities.decode(article.content)
                                  .replaceAll('<p><iframe', '<p class="embed-container"><iframe')
                                  .replaceAll('<oembed url', '<iframe frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen src')
                                  .replaceAll('watch?v=', 'embed/')
                                  .replaceAll(/&t=(\d+)s/g, '?start=$1');

        article.reading_time = article.reading_time ? `${article.reading_time} min read` : readingTime(article.content).text;

        return (
          <div>
            <MetaTags>
              <title>{article.title}</title>
              <meta name="title" content={article.title} />
              <meta name="description" content={article.subtitle} />

              <meta property="og:title" content={article.title} />
              <meta property="og:description" content={article.subtitle} />
              <meta property="og:image" content={imageUrl} />
              <meta property="og:url" content={window.location.href} />

              <meta property="twitter:title" content={article.title} />
              <meta property="twitter:description" content={article.subtitle} />
              <meta property="twitter:image" content={imageUrl} />
              <meta property="twitter:url" content={window.location.href} />
            </MetaTags>
            <div
              className="uk-height-large uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
              data-src={imageUrl}
              data-srcset={imageUrl}
              data-uk-img
            ></div>

            <div className="uk-section uk-padding-remove-top">
              <article className="uk-article uk-container uk-container-small">
                <p className="uk-article-meta"><Moment format="MMM Do YYYY">{article.published_at}</Moment></p>

                <h1 className="uk-article-title">{article.title}</h1>

                <div className="uk-flex uk-flex-bottom uk-flex-between">
                  <div className="author">
                    <div className="avatar uk-border-circle" data-src={avatarUrl} data-uk-img="" /> 
                    <span className="name">{article.user.name},</span> <span className="position">{article.user.position}</span>
                  </div>
                  <div className="uk-text-right uk-text-small uk-flex-none">[{article.reading_time}]</div>
                </div>

                <p className="uk-text-lead">{article.subtitle}</p>
                
                <div className="social-share uk-flex uk-flex-right">
                  <FacebookShareButton url={window.location.href}><FacebookIcon size={25} round /></FacebookShareButton>
                  <LinkedinShareButton url={window.location.href}><LinkedinIcon size={25} round /></LinkedinShareButton>
                  <TwitterShareButton url={window.location.href}><TwitterIcon size={25} round /></TwitterShareButton>
                </div>

                <ReactMarkdown 
                  source={article.content} 
                  escapeHtml={false} 
                  className="uk-text-large article-content"
                />

                {article.tags.length > 0 && (<>
                  <h5 className="uk-text-bold uk-text-primary uk-text-uppercase uk-margin-remove-bottom">Tags</h5>
                  <ul className="uk-breadcrumb uk-margin-remove-top uk-text-primary">
                    {article.tags.map(({id, tag}) => {
                      return <li key={`tag__${id}`}>{tag}</li>;
                    })}
                  </ul>
                </>)}
              </article>
            </div>

            {article.category && <RelatedArticles articleID={article.id} categoryID={article.category.id} />}
          </div>
        );
      }}
    </Query>
  );
};

export default Article;
