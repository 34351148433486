import gql from "graphql-tag";

const CATEGORY_ARTICLES_QUERY = gql`
  query Category($id: ID!, $today: String!) {
    category(id: $id) {
      id
      name
      articles(sort: "published_at:desc", where: { published_at_lte: $today }) {
        id
        slug
        title
        subtitle
        content
        image {
          url
        }
        category {
          id
          name
        }
        highlight
        user {
          username
          name
          avatar {
            url
          }
        }
        reading_time
      }
    }
  }
`;

export default CATEGORY_ARTICLES_QUERY;
