import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';

export const initializeGA = () => {
  ReactGA.initialize('UA-90118844-1');
};

export const initializeSegment = () => {
  window.analytics.load("tswvcncvaBULamWO8hDnKvdq0VlFwgMQ");
};

export const logPageView = (location) => {
  const page = (location && location.pathname) || window.location.pathname;
  //ReactGA.set({ page: page });
  //ReactGA.pageview(page);
  window.analytics.page(page);
  console.log(`Page View logged for: ${page}`);
}

export const withTracker = (WrappedComponent) => {
  //initializeGA();
  initializeSegment();

  return (props) => {
    const history = useHistory();

    useEffect(() => {
      history.listen(logPageView);
      logPageView();
    }, [history]);

    return <WrappedComponent {...props} />;
  };
};